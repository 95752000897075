<template>
    <section class="partnership my-5">
        <h2 class="partnership__title mt-3">{{ getTechnologyTransferPageInfo('title') }}</h2>

<!--        <p class="partnership__text col-md-7">-->
<!--            Университет ИТМО является ведущим вузом России-->
<!--            в сфере науки и образования. Научные достижения Университета ИТМО получили широкое признание, что-->
<!--            подтверждается научными проектами и разработками, широким международным научным сотрудничеством, количеством-->
<!--            научных публикаций, постоянно расширяющимся кругом партнеров. Для расширения партнерской сети в университете-->
<!--            создан Центр научного бизнес партнерства, отвечающий за комплексное взаимодействие-->
<!--            с российскими и международными компаниями-->
<!--            на принципах открытого сетевого взаимодействия и их вовлечения в глобальную экосистему университета.-->
<!--        </p>-->

        <div class="d-flex justify-content-center w-100">
            <div style="width: 1448px;">
                <div class="partnership_cards d-flex justify-content-center flex-column flex-lg-row mt-5">
                    <div class="d-flex flex-column col-lg-8">
                        <div class="h-100">
                            <div class="d-flex flex-column flex-md-row h-100">
                                <div
                                    class="technology-transfer__partnership-agreement partnership_card partnership_card--primary col-12 col-md-5 p-3">
                                    <div class="partnership-agreement__text">Соглашение о партнeрстве</div>
                                    <div class="card-agreement__text mt-2 d-flex">
                                        <div class="partnership-agreement__download me-2">
                                            <a :href="getAgreementFile">Скачать соглашение</a>
                                        </div>
                                        <div><img src="../../assets/views/partnership/partnership-arrow.svg" alt="">
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="partnership_card d-flex flex-column partnership_card--light col-12 col-md-7 mt-3 mt-md-0 ms-md-3"
                                    @click="goToPage('ProjectExpertise')"
                                >
                                    <div class="d-flex partnership-scientific">
                                        <div class="partnership-scientific__text ms-4 mt-3">
                                            <router-link :to="{ name: 'ProjectExpertise' }">
                                                Экспертиза проектов
                                            </router-link>
                                        </div>

                                        <img
                                            class="d-block mt-auto ms-auto"
                                            src="../../assets/views/partnership/strategic-partnership1.svg"
                                            alt=""
                                        >
                                    </div>

                                    <img
                                        class="d-inline-block ms-auto technology-transfer__expertise"
                                        src="../../assets/views/partnership/strategic-partnership2.svg"
                                        alt=""
                                    >
                                </div>
                            </div>
                        </div>

                        <div
                            class="partnership_card partnership_card--light d-flex flex-column mt-3"
                            @click="goToPage('TransferTechnologyCenter')"
                        >
                            <div class="partnership-innovation__text ms-4 mt-3">
                                <router-link :to="{ name: 'TransferTechnologyCenter' }">
                                    Центр трансфера технологий Университета ИТМО
                                </router-link>
                            </div>
                            <img
                                class="d-block mt-auto technology-transfer__tt ms-auto"
                                src="../../assets/views/partnership/innovation-partnership.svg"
                                alt=""
                            >
                        </div>
                    </div>

                    <div
                        class="technology-transfer__technopark-card partnership_card partnership_card--light d-flex flex-column col-lg-4 ms-lg-3 mt-3 mt-lg-0 h-100"
                        @click="goToPage('Technopark')"
                    >
                        <div class="partnership-educational__text ms-4 mt-3 mb-3 mb-lg-5 mb-xxl-3">
                            <router-link :to="{ name: 'Technopark' }">
                                Акселератор и Технопарк
                            </router-link>
                        </div>
                        <img
                            class="technology-transfer__technopark d-block mt-lg-5 mt-xl-1"
                            src="../../assets/views/partnership/educational-partnership1.svg"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";

export default {
    name: "TechnologyTransfer",

    setup() {
        const store = useStore()

        store.dispatch('widgets/getPartnershipAgreementWidgets')

        const partnershipAgreementWidgets = computed(() => store.getters['widgets/partnershipAgreementWidgets'])

        const getAgreementFile = computed(() => {
            if (partnershipAgreementWidgets.value) {
                return `/${partnershipAgreementWidgets.value.agreement_file.split('/').slice(3).join('/')}`
            }

            return ''
        })

        const { isMenuVisible, toggleMenu, goToPage } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getTechnologyTransferPage')
        const technologyTransferPage = computed(() => store.getters['pages/technologyTransferPage'])

        const getTechnologyTransferPageInfo = (key) => {
            if (technologyTransferPage.value) {
                return technologyTransferPage.value[key]
            }

            return ''
        }

        return {
            getAgreementFile,
            getTechnologyTransferPageInfo,
            goToPage
        }
    }
}
</script>
